import React from "react";
import Navbar from "../components/Navbar";
import HeroSection from "../components/HeroSection";
import MobileNavbar from "../components/MobileNavbar";
import Footer from "../components/Footer";
import useLoadScript from "../Utility/useLoadScript";
import ExternalScriptsLoader from "../Scripts/Home_dl_lps";
import HowItWorks from "../components/HowItWorks";
import Contact from "../components/Contact";
import Image from "../assets/images/photos/3.webp";
export default function DlLp1() {
  useLoadScript("../Scripts/Home_dl_lps.js");
  const HeroData = {
    Heading: `Affordable <br/> Medicare Solutions`,
    subHeading: `Coverage You Need <br/>
At Prices You Can Afford`,
    Text: `Seniors Can Now Save 
$1,000’s with These 
New Medicare Benefits`,
    Image: Image,
  };
  const contentData = {
    heading: "We’ll Help You Access the Medicare Benefits You Deserve",
    subHeading:
      "Looking for the best Medicare plan to cover your health needs?",
    description:
      "We can connect you with services that offer comprehensive coverage and help you find the right plan for your situation. By working with trusted professionals, you may qualify for additional benefits like:",
    steps: [
      "Prescription Drug Coverage",
      "Dental Exams, Fillings, and Dentures",
      "Routine Vision Care and Eyeglasses",
      "Hearing Exams, Hearing Aids, and Batteries",
      "Transportation to Doctor Appointments",
      "And Much More!",
    ],
    subTextHeading: "Find Out If You Qualify Today!",
    subText: `Let us take the hassle out of finding the right Medicare solution for you. We’ll connect you with experts who can ensure you're getting the most out of your Medicare benefits.`,
  };
  const contactData = {
    Heading: `Don’t Miss Out on <br/> Extra Benefits <br/>
    Call Us to 
    Maximize <br/> Your Medicare`,
subHeading: ``,
Text: ``,
  };
  const PhoneNumber = {
    PhoneNo: "(877) 865-0060",
  };
  return (
    <div>
       <ExternalScriptsLoader />
      <Navbar PhoneNumber={PhoneNumber} />
      <MobileNavbar PhoneNumber={PhoneNumber} />
      <HeroSection HeroData={HeroData} PhoneNumber={PhoneNumber} />
      <HowItWorks contentData={contentData} PhoneNumber={PhoneNumber} />
      <Contact contactData={contactData} PhoneNumber={PhoneNumber} />
      <Footer PhoneNumber={PhoneNumber} />
    </div>
  );
}
