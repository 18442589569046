import React from "react";
import Navbar from "../components/Navbar";
import HeroSection from "../components/HeroSection";
import MobileNavbar from "../components/MobileNavbar";
import Footer from "../components/Footer";
import useLoadScript from "../Utility/useLoadScript";
import ExternalScriptsLoader from "../Scripts/Home_gg_lps";
import HowItWorks from "../components/HowItWorks";
import Contact from "../components/Contact";
import Image from "../assets/images/photos/1.png";
export default function GgLp3() {
  useLoadScript("../Scripts/Home_gg_lps.js");
  const HeroData = {
    Heading: `Don’t Settle for Less`,
    subHeading: `Upgrade Your Medicare 
with the Right Advantage Plan`,
    Text: `Save Big with the 
Latest Medicare Benefits 
Available for Seniors`,
    Image: Image,
  };
  const contentData = {
    heading: "We’ll Help You Access the Medicare Benefits You Deserve",
    subHeading:
      "Looking for the best Medicare plan to cover your health needs?",
    description:
      "We can connect you with services that offer comprehensive coverage and help you find the right plan for your situation. By working with trusted professionals, you may qualify for additional benefits like:",
    steps: [
      "Prescription Drug Coverage",
      "Dental Exams, Fillings, and Dentures",
      "Routine Vision Care and Eyeglasses",
      "Hearing Exams, Hearing Aids, and Batteries",
      "Transportation to Doctor Appointments",
      "And Much More!",
    ],
    subTextHeading: "Find Out If You Qualify Today!",
    subText: `Let us take the hassle out of finding the right Medicare solution for you. We’ll connect you with experts who can ensure you're getting the most out of your Medicare benefits.`,
  };
  const contactData = {
    Heading: `Your Ideal Plan Awaits
Call for Expert 
Medicare Advantage Advice!`,
subHeading: ``,
Text: ``,
  };
  const PhoneNumber = {
    PhoneNo: "(877) 865-0060",
  };
  return (
    <div>
      <ExternalScriptsLoader />
      <Navbar PhoneNumber={PhoneNumber} />
      <MobileNavbar PhoneNumber={PhoneNumber} />
      <HeroSection HeroData={HeroData} PhoneNumber={PhoneNumber} />
      <HowItWorks contentData={contentData} PhoneNumber={PhoneNumber} />
      <Contact contactData={contactData} PhoneNumber={PhoneNumber} />
      <Footer PhoneNumber={PhoneNumber} />
    </div>
  );
}
