import React from "react";
import image from "../assets/images/photos/7.webp";

export default function HowItWorks({ PhoneNumber, contentData }) {
  const { heading, subHeading, description, steps, subText, subTextHeading } =
    contentData;

  return (
    <div className="HowWorks">
      <div className="medicare-section">
        <div className="img">
          <img src={image} alt="How it works" />
        </div>
        <div className="content">
          <h1>{heading}</h1>
          <h3>{subHeading}</h3>
          <p>{description}</p>
          <ul>
            {steps.map((step, index) => (
              <p key={index}>
                <span className="icon">&#9989;</span>
                <li>{step}</li>
              </p>
            ))}
          </ul>
          <h4>{subTextHeading}</h4>

          <p>{subText}</p>
          {/* <p>Phone: {PhoneNumber}</p> */}
        </div>
      </div>
    </div>
  );
}
