import React, { useRef, useEffect } from "react";
import scrollAnimation from "../../utils/scrollAnimation";


export default function HeroSection({ PhoneNumber, HeroData }) {
  const cardRefs = [useRef(), useRef(), useRef()];

  useEffect(() => {
    cardRefs.forEach((ref) => {
      if (ref.current) {
        scrollAnimation.observeElement(ref.current);
      }
    });
  }, []);

  const formatPhoneNumberForTel = (number) => {
    return number.replace(/\D/g, "");
  };

  const telPhoneNumber = `+1${formatPhoneNumberForTel(PhoneNumber.PhoneNo)}`;

  return (
    <div className="HeroSection">
      <div className="content">
        <h1 dangerouslySetInnerHTML={{__html: HeroData.Heading}}/>
        <h3  dangerouslySetInnerHTML={{__html: HeroData.subHeading}}/>
        <img className="mb" src={HeroData.Image} alt="Doctor" />
        <p>{HeroData.Text}</p>
        <div className="btn-group">
          <a href={`tel:${telPhoneNumber}`}> CALL NOW:{PhoneNumber.PhoneNo}</a>
        </div>
      </div>
      <div className="image-container">
        <img src={HeroData.Image} alt="Doctor" />
      </div>
    </div>
  );
}
