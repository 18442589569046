import React, { useEffect, useRef } from "react";
import scrollAnimation from "../../utils/scrollAnimation";
import image from "../assets/images/photos/6.webp";
export default function Contact({ PhoneNumber,contactData }) {
  const cardRefs = [useRef(), useRef(), useRef()];

  useEffect(() => {
    cardRefs.forEach((ref) => {
      if (ref.current) {
        scrollAnimation.observeElement(ref.current);
      }
    });
  }, []);
  const formatPhoneNumberForTel = (number) => {
    return number.replace(/\D/g, "");
  };


  const telPhoneNumber = `+1${formatPhoneNumberForTel(PhoneNumber.PhoneNo)}`;
  useEffect(() => {}, [cardRefs]);
  return (
    <div className="Contact">
      <div class="testimonial-section">
        <div class="testimonial-container">
          <div class="testimonial-content">
            <h1 dangerouslySetInnerHTML={{__html: contactData.Heading}}/>
            <h2>{contactData.subHeading}</h2>
            <p>{contactData.Text}</p>
            <div className="btn-group">
              <a href={`tel:${telPhoneNumber}`}>
                {" "}
                CALL NOW:{PhoneNumber.PhoneNo}
              </a>
            </div>
          </div>
          <div class="testimonial-image">
            <img src={image} alt="Healthcare Interaction" />
          </div>
        </div>
      </div>
    </div>
  );
}
