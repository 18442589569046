import React,{useEffect,useRef} from "react";
import scrollAnimation from "../../utils/scrollAnimation";
import image from "../assets/images/photos/3.webp";
export default function WhyChooseUs({ PhoneNumber}) {
  const cardRefs = [useRef(), useRef(), useRef()];

  useEffect(() => {
    cardRefs.forEach(ref => {
      if (ref.current) {
        scrollAnimation.observeElement(ref.current);
      }
    });
  }, []);
  const formatPhoneNumberForTel = (number) => {
    return number.replace(/\D/g, "");
  };
  useEffect (()=>{

  }, [cardRefs])
  const telPhoneNumber = `+1${formatPhoneNumberForTel(PhoneNumber.PhoneNo)}`;
  return (
   <div className="WhyChooseUs">
    <div className="choose-flex">
      
      <div className="choose-content">
        <h1>Medicare Advantage</h1>
        <h3>Enhance Your Healthcare Coverage with Tailored Plans</h3>
        <p>As we age, healthcare needs evolve. Whether it's managing chronic conditions or accessing more preventive care, the right plan is essential. Medicare Advantage plans provide an all-in-one solution, covering expenses that Original Medicare doesn’t—like prescription drugs, dental, vision, and wellness programs.</p>
        <p>These plans, offered by private insurance companies, are designed to meet your changing healthcare needs. We connect you with top experts to help find a plan that ensures your ongoing wellness.</p>
        <div className="btn-group">
            <a  href={`tel:${telPhoneNumber}`}> CALL NOW:{PhoneNumber.PhoneNo}</a>
          </div>
      </div>
      <div className="choose-img">
        <img src={image} alt="" />
      </div>
    </div>
    
 
  
  
   </div>
  );
}
