import React from "react";
import Navbar from "../components/Navbar";
import MobileNavbar from "../components/MobileNavbar";
import Footer from "../components/Footer";
export default function Disclaimer() {
  const PhoneNumber = {
    PhoneNo: "(877) 865-0060",
  };
  return (
    <div>
      <Navbar PhoneNumber={PhoneNumber} />
      <MobileNavbar PhoneNumber={PhoneNumber} />
      <div
        data-elementor-type="wp-page"
        data-elementor-id="1400"
        class="elementor elementor-1400 o"
        data-elementor-post-type="page"
      >
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-f87791b elementor-section-boxed elementor-section-height-default elementor-section-height-default bb"
          data-id="f87791b"
          data-element_type="section"
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-6ad6a81"
              data-id="6ad6a81"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-c495d4a elementor-widget elementor-widget-heading"
                  data-id="c495d4a"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h1 class="elementor-heading-title elementor-size-default">
                      DISCLAIMER
                    </h1>{" "}
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-396c208 elementor-widget elementor-widget-text-editor"
                  data-id="396c208"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div class="elementor-widget-container">
                    <p>
                      <strong>Last updated&nbsp;July 25, 2022</strong>
                    </p>{" "}
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-4e5c550 elementor-widget elementor-widget-text-editor"
                  data-id="4e5c550"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div class="elementor-widget-container">
                    <p>
                      PremiumMedicareAdvisors.com is a referral source that
                      provides information and access to a helpline to match
                      consumers with companies that may provide certain
                      insurance coverage to them. PremiumMedicareAdvisors.com
                      does not act as an insurance broker and does not make
                      decisions about insurance coverage that may be available
                      to you. PremiumMedicareAdvisors.com doesn’t promise a
                      specific outcome or the results you may achieve by calling
                      the helpline. The helpline is free to call but the
                      services or programs that you pursue may have costs
                      associated with them. Neither PremiumMedicareAdvisors.com
                      nor any of the supplemental insurance plans to which you
                      may be connected are endorsed by the U.S. Government or
                      the federal Medicare program.*Certain beneficiaries may
                      qualify for help paying their Part B Premium ($144.60 as
                      of 2020) as part of each States Medicaid/or Medical
                      Assistance Program. Our partners assists beneficiaries
                      with applications and initial eligibility screening, but
                      only the relevant state agency may determine eligibility.
                      Provision of financial information to our partners is
                      optional and does not affect enrollment eligibility.
                      Residents of certain regions may also qualify for a Part B
                      Buyback Plan covering up to $135 of the Part B Premium.Our
                      goal is to provide exceptional service. One of our agents
                      may reach out to you to discuss your order, ask for
                      feedback, and/or see if you need any assistance with your
                      products, services, or plans, at the phone number you
                      provided regardless of your do-not-call list status. You
                      may opt-out of further contact at any time by simply
                      telling our customer service team that you would no longer
                      like to be contacted. In the event that our team is unable
                      to reach you by phone, they may send you a text message
                      letting you know that we called. Both our text messages
                      and phone calls may be sent or connected utilizing
                      automated software. Carrier charges may apply. You may
                      opt-out of any future contact via text message by replying
                      anytime with “STOP”.
                    </p>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
       
       
         
        </section>
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-3851288 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="3851288"
          data-element_type="section"
        >
           <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-b61b9ba"
              data-id="b61b9ba"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-f128853 elementor-widget elementor-widget-heading"
                  data-id="f128853"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h1 class="elementor-heading-title elementor-size-default">
                      WEBSITE DISCLAIMER
                    </h1>{" "}
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-d9075ea elementor-widget elementor-widget-text-editor"
                  data-id="d9075ea"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div class="elementor-widget-container">
                    <p>
                      The information provided by&nbsp;Premium Medicare
                      Advisors&nbsp;(“we,” “us,” or “our”)
                      on&nbsp;premiummedicareadvisors.com
                      (the&nbsp;“Site”)&nbsp;is for general informational
                      purposes only. All information on&nbsp;the Site&nbsp;is
                      provided in good faith, however we make no representation
                      or warranty of any kind, express or implied, regarding the
                      accuracy, adequacy, validity, reliability, availability,
                      or completeness of any information on&nbsp;the Site. UNDER
                      NO CIRCUMSTANCE SHALL WE HAVE ANY LIABILITY TO YOU FOR ANY
                      LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE
                      OF&nbsp;THE SITE&nbsp;OR RELIANCE ON ANY INFORMATION
                      PROVIDED ON&nbsp;THE SITE. YOUR USE OF&nbsp;THE
                      SITE&nbsp;AND YOUR RELIANCE ON ANY INFORMATION ON&nbsp;THE
                      SITE&nbsp;IS SOLELY AT YOUR OWN RISK.
                    </p>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-37d1bf5"
              data-id="37d1bf5"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-49a1b28 elementor-widget elementor-widget-heading"
                  data-id="49a1b28"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h1 class="elementor-heading-title elementor-size-default">
                      PROFESSIONAL DISCLAIMER
                    </h1>{" "}
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-8cba71e elementor-widget elementor-widget-text-editor"
                  data-id="8cba71e"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div class="elementor-widget-container">
                    <p>
                      The Site cannot and does not contain&nbsp;medicare
                      advantage / medicare insurance&nbsp;advice.
                      The&nbsp;medicare advantage / medicare
                      insurance&nbsp;information is provided for general
                      informational and educational purposes only and is not a
                      substitute for professional advice. Accordingly, before
                      taking any actions based upon such information, we
                      encourage you to consult with the appropriate
                      professionals. We do not provide any kind of&nbsp;medicare
                      advantage / medicare insurance&nbsp;advice.&nbsp;THE USE
                      OR RELIANCE OF ANY INFORMATION CONTAINED ON&nbsp;THE
                      SITE&nbsp;IS SOLELY AT YOUR OWN RISK.
                    </p>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-4a1237b elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="4a1237b"
          data-element_type="section"
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-d3acb50"
              data-id="d3acb50"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-858fea3 elementor-widget elementor-widget-heading"
                  data-id="858fea3"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h1 class="elementor-heading-title elementor-size-default">
                      TCPA DISCLAIMER
                    </h1>{" "}
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-b1b37bc elementor-widget elementor-widget-text-editor"
                  data-id="b1b37bc"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div class="elementor-widget-container">
                    <p>
                      Notwithstanding any current or prior election to opt in or
                      opt out of receiving telemarketing calls, or SMS messages
                      (text messages) from our representatives, agents,
                      affiliates, you expressly consent to be contacted by
                      PREMIUM MEDICARE ADVISORS, our agents, representatives,
                      affiliates, or anyone calling on our behalf. This is for
                      any purposes relating to your request for medicare
                      advantage / medicare insurance service, at any telephone
                      number, or physical or electronic address you provide or
                      at which you may be reached. You agree we may contact you
                      in any way, including SMS messages (text messages), calls
                      using prerecorded messages or artificial voice, and calls
                      and messages delivered using auto telephone dialing system
                      or an automatic texting system.
                    </p>
                    <p>
                      Automated messages may be played when the telephone is
                      answered, whether by you or someone else. In the event
                      that an agent or representative of PREMIUM MEDICARE
                      ADVISORS calls, he or she may also leave a message on your
                      answering machine, voice mail, or send one via text. You
                      certify that the telephone numbers that you have provided,
                      are in fact your contact numbers, and you agree to receive
                      calls at each phone number you have provided PREMIUM
                      MEDICARE ADVISORS. You agree to promptly alert PREMIUM
                      MEDICARE ADVISORS in the event that you stop using a
                      particular phone number. Your cell/mobile telephone
                      provider will charge you according the type of account you
                      carry with those companies. You may opt out of any future
                      contact via text message by replying anytime with “STOP”.
                      You agree that PREMIUM MEDICARE ADVISORS may contact you
                      by email, using any email address you have provided or
                      that you provide in the future. You may opt out of any
                      future contact via email message by replying anytime with
                      “UNSUBSCRIBE”. PREMIUM MEDICARE ADVISORS may listen to
                      and/or record calls between you and any representative
                      without notice, as permitted by applicable laws. For
                      example we listen to calls to monitor for quality
                      purposes.
                    </p>
                    <p>&nbsp;</p>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer PhoneNumber={PhoneNumber} />
    </div>
  );
}
