import React from "react";
import Navbar from "./components/Navbar";
import HeroSection from "./components/HeroSection";
import WhyChooseUs from "./components/WhyChooseUs";
import Services from "./components/Services";
import HowItWorks from "./components/HowItWorks";
import Contact from "./components/Contact";
import MobileNavbar from "./components/MobileNavbar";
import Footer from "./components/Footer";
import Image from "./assets/images/photos/2.webp";
export default function landingPage() {
  const HeroData = {
    Heading: `Welcome to <br/> Premium Medicare <br/> Advisors`,
    subHeading: `Simplify  Your Medicare <br/> Journey Today`,
    Text: `Choosing the right Medicare plan doesn’t have to be stressful. Our
          team connects you with trusted experts who help you find tailored
          solutions that meet your unique needs.`,
    Image: Image,
  };
  const contentData = {
    heading: "Understanding Medicare Insurance",
    description: "Medicare insurance can be confusing. Let us help you find the right plan for your needs.",
    steps: [
      "Understanding your Medicare options and selecting the plan that suits you",
      "Getting enrolled in a plan that maximizes your coverage",
      "Ensuring you make the most out of your benefits"
    ],
    subText: `With our help, securing the right health coverage is a breeze!`
  };
  const contactData = {
    Heading: `One Call Can Secure the <br/> Best Coverage for <br/> Your Health`,
    subHeading: ``,
    Text: ``,
  };
  
  const PhoneNumber = {
    PhoneNo: "(877) 865-0060",
  };

  return (
    <div>
      <Navbar PhoneNumber={PhoneNumber} />
      <MobileNavbar PhoneNumber={PhoneNumber} />
      <HeroSection HeroData={HeroData} PhoneNumber={PhoneNumber} />
      <HowItWorks contentData={contentData} PhoneNumber={PhoneNumber} />
      <WhyChooseUs PhoneNumber={PhoneNumber} />
      <Services PhoneNumber={PhoneNumber} />
      <Contact contactData={contactData} PhoneNumber={PhoneNumber} />
      <Footer PhoneNumber={PhoneNumber} />
    </div>
  );
}
