import React from "react";
import Navbar from "../components/Navbar";
import MobileNavbar from "../components/MobileNavbar";
import Footer from "../components/Footer";
export default function TermsCondition() {
  const PhoneNumber = {
    PhoneNo: "(877) 403-2065",
  };
  return (
    <div>
      <Navbar PhoneNumber={PhoneNumber} />
      <MobileNavbar PhoneNumber={PhoneNumber} />
      <div id="content-wrap" className="container clr o">
        <div id="primary" className="content-area clr">
          <div id="content" className="site-content clr">
            <article className="single-page-article clr">
              <div className="entry clr">
                <section className="elementor-section elementor-top-section bb">
                  <div className="elementor-container">
                    <div className="elementor-column elementor-col-100">
                      <div className="elementor-widget-wrap">
                        <div className="elementor-widget elementor-widget-heading">
                          <h2 className="elementor-heading-title">
                            Terms and Conditions
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="elementor-container">
                    <div className="elementor-column elementor-col-100">
                      <div className="elementor-widget-wrap">
                        <div className="elementor-widget elementor-widget-text-editor">
                          <p>
                            These Terms and Conditions constitute a legally
                            binding agreement made between you, whether
                            personally or on behalf of an entity (“you”) and
                            Premium Medicare Advisors (“we,” “us,” or “our”),
                            concerning your access to and use of the
                            PremiumMedicareAdvisors.com website.
                            <br />
                            <br />
                            You agree that by accessing the Site, you have read,
                            understood, and agree to be bound by all of these
                            Terms and Conditions. If you do not agree with all
                            of these Terms and Conditions, you are expressly
                            prohibited from using the Site and must discontinue
                            use immediately.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section className="elementor-section elementor-top-section">
                  <div className="elementor-container">
                    <div className="elementor-column elementor-col-100">
                      <div className="elementor-widget-wrap">
                        <div className="elementor-widget elementor-widget-heading">
                          <h2 className="elementor-heading-title">
                            Links to Other Websites
                          </h2>
                        </div>
                        <div className="elementor-widget elementor-widget-text-editor">
                          <p>
                            The Site may contain links to other websites
                            (“Third-Party Websites”) as well as content
                            originating from third parties. We are not
                            responsible for any Third-Party Websites accessed
                            through the Site or any Third-Party Content posted
                            on, available through, or installed from the Site.
                            <br />
                            <br />
                            We strongly advise you to read the Third-Party
                            Website terms and conditions and the privacy
                            policies of any site or services you visit or use.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section className="elementor-section elementor-top-section">
                  <div className="elementor-container">
                    <div className="elementor-column elementor-col-100">
                      <div className="elementor-widget-wrap">
                        <div className="elementor-widget elementor-widget-heading">
                          <h2 className="elementor-heading-title">
                            Intellectual Property
                          </h2>
                        </div>
                        <div className="elementor-widget elementor-widget-text-editor">
                          <p>
                            Unless otherwise indicated, the Site is our
                            proprietary property. All source code, databases,
                            functionality, software, website designs, audio,
                            video, text, photographs, and graphics on the Site
                            are owned or controlled by us or licensed to us and
                            are protected by copyright and trademark laws.
                            <br />
                            <br />
                            The Content is provided on the Site “AS IS” for your
                            information and personal use only. No part of the
                            Site may be copied, reproduced, republished,
                            uploaded, posted, or otherwise exploited for any
                            commercial purpose without our express prior written
                            permission.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section className="elementor-section elementor-top-section">
                  <div className="elementor-container">
                    <div className="elementor-column elementor-col-100">
                      <div className="elementor-widget-wrap">
                        <div className="elementor-widget elementor-widget-heading">
                          <h2 className="elementor-heading-title">
                            Indemnification
                          </h2>
                        </div>
                        <div className="elementor-widget elementor-widget-text-editor">
                          <p>
                            You agree to indemnify and hold Premium Medicare
                            Advisors harmless from any loss, damage, liability,
                            or demand, including reasonable attorneys’ fees,
                            arising out of your use of the Site or any breach of
                            these Terms and Conditions.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </article>
          </div>
        </div>
      </div>
      <Footer PhoneNumber={PhoneNumber} />
    </div>
  );
}
