import React from "react";
// import scrollAnimation from "../../utils/scrollAnimation";
import image from "../assets/images/photos/8.png";
export default function Services({ PhoneNumber }) {
  // const cardRefs = [useRef(), useRef(), useRef()];

  // useEffect(() => {
  //   cardRefs.forEach((ref) => {
  //     if (ref.current) {
  //       scrollAnimation.observeElement(ref.current);
  //     }
  //   });
  // }, []);
  // // const formatPhoneNumberForTel = (number) => {
  // //   return number.replace(/\D/g, "");
  // // };
  // useEffect (()=>{

  // }, [cardRefs])
  // const telPhoneNumber = `+1${formatPhoneNumberForTel(PhoneNumber.PhoneNo)}`;
  return (
    <div className="Services">
      <div class="medicare-section">
      <div className="img">
          <img src={image} alt="" />
        </div>
        <div class="content">
          <h1>Personalized Support to Find Your Medicare Plan</h1>
          <h3>Let Us Help You Secure the Perfect Healthcare Plan</h3>

          <p>
            Medicare insurance can be confusing. Let us help you find the right
            plan for your needs.
          </p>
        
          <p>
            At Premium Medicare Advisors, we focus on helping individuals find
            Medicare plans tailored to their needs. We understand that
            healthcare is personal, which is why we match you with professionals
            who will find a plan designed for your unique situation.
          </p>
          <p>
            Rest assured, with our network, you’ll compare top Medicare
            insurance plans and get the coverage that best suits your budget and
            needs.
          </p>
        </div>
        
      </div>
      
    </div>
  );
}
