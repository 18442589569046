import React from "react";
import Navbar from "../components/Navbar";
import MobileNavbar from "../components/MobileNavbar";
import Footer from "../components/Footer";

export default function PrivacyPolicy() {
  const PhoneNumber = {
    PhoneNo: "(877) 865-0060",
  };
  return (
    <div>
      <Navbar PhoneNumber={PhoneNumber} />
      <MobileNavbar PhoneNumber={PhoneNumber} />
      <div
        data-elementor-type="wp-page"
        data-elementor-id="12"
        class="elementor elementor-12 o"
        data-elementor-post-type="page"
      >
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-7c9a339 elementor-section-boxed elementor-section-height-default elementor-section-height-default bb"
          data-id="7c9a339"
          data-element_type="section bb"
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-97b2fe7"
              data-id="97b2fe7"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-01de56a elementor-widget elementor-widget-heading"
                  data-id="01de56a"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h1 class="elementor-heading-title elementor-size-default">
                      Privacy Policy
                    </h1>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
       
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-60138d8"
              data-id="60138d8"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-07900ea elementor-widget elementor-widget-text-editor"
                  data-id="07900ea"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div class="elementor-widget-container">
                    <p>
                      Intermedia Marketing Group LLC operates the
                      PremiumMedicareAdvisors.com website, which provides the
                      SERVICE.This page informs website visitors regarding our
                      policies regarding the collection, use, and disclosure of
                      Personal Information if anyone decides to use our Service,
                      the Premium Medicare Advisors website.If you choose to use
                      our Service, then you agree to the collection and use of
                      information in relation to this policy. The Personal
                      Information that we collect is used for providing and
                      improving the Service. We will not use or share your
                      information except as described in this Privacy Policy.The
                      terms used in this Privacy Policy have the same meanings
                      as our Terms and Conditions, which are accessible at
                      PremiumMedicareAdvisors.comunless otherwise defined in
                      this Privacy Policy.
                    </p>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-870a2b7 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="870a2b7"
          data-element_type="section"
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-85ff20f"
              data-id="85ff20f"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-030b47f elementor-widget elementor-widget-heading"
                  data-id="030b47f"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h1 class="elementor-heading-title elementor-size-default">
                      Definitions
                    </h1>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-ca98c30 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="ca98c30"
          data-element_type="section"
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-78268c6"
              data-id="78268c6"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-3fafe91 elementor-widget elementor-widget-heading"
                  data-id="3fafe91"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h2 class="elementor-heading-title elementor-size-default">
                      Service
                    </h2>{" "}
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-369fc2e elementor-widget elementor-widget-text-editor"
                  data-id="369fc2e"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div class="elementor-widget-container">
                    <div
                      id="paragraph-kdEmbHTWuh2"
                      class="c-paragraph c-wrapper"
                      data-v-71a73cda=""
                    >
                      <div class="paragraph-kdEmbHTWuh2 text-output cparagraph-kdEmbHTWuh2 none noBorder radius0 none">
                        <p>
                          Service is the PremiumMedicareAdvisors.com operated by
                          Intermedia Marketing Group LLC.
                        </p>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-df8770c elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="df8770c"
          data-element_type="section"
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-6679ae9"
              data-id="6679ae9"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-6bbcf7a elementor-widget elementor-widget-heading"
                  data-id="6bbcf7a"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h2 class="elementor-heading-title elementor-size-default">
                      Personal Data
                    </h2>{" "}
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-8150311 elementor-widget elementor-widget-text-editor"
                  data-id="8150311"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div class="elementor-widget-container">
                    <div
                      id="paragraph-ZGw02MFA_MH"
                      class="c-paragraph c-wrapper"
                      data-v-71a73cda=""
                    >
                      <div class="paragraph-ZGw02MFA_MH text-output cparagraph-ZGw02MFA_MH none noBorder radius0 none">
                        <div>
                          <p>
                            Personal Data pertains to information about a living
                            person(s) who can be identified from this data or
                            other information in our possession or likely to
                            come into our possession.
                          </p>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-477f25d elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="477f25d"
          data-element_type="section"
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-d09c987"
              data-id="d09c987"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-12f6090 elementor-widget elementor-widget-heading"
                  data-id="12f6090"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h2 class="elementor-heading-title elementor-size-default">
                      Usage Data
                    </h2>{" "}
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-4f87cb1 elementor-widget elementor-widget-text-editor"
                  data-id="4f87cb1"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div class="elementor-widget-container">
                    <div
                      id="paragraph-LqI80kjt4z1"
                      class="c-paragraph c-wrapper"
                      data-v-71a73cda=""
                    >
                      <div class="paragraph-LqI80kjt4z1 text-output cparagraph-LqI80kjt4z1 none noBorder radius0 none">
                        <div>
                          <p>
                            Usage Data has been collected automatically and is
                            generated by the use of the Service, such as the
                            duration of a visit to the site.
                          </p>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-61ac3fc elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="61ac3fc"
          data-element_type="section"
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-99e183d"
              data-id="99e183d"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-b1a3b89 elementor-widget elementor-widget-heading"
                  data-id="b1a3b89"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h2 class="elementor-heading-title elementor-size-default">
                      Cookies
                    </h2>{" "}
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-58224ee elementor-widget elementor-widget-text-editor"
                  data-id="58224ee"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div class="elementor-widget-container">
                    <div
                      id="paragraph-hyvQJfY5RlH"
                      class="c-paragraph c-wrapper"
                      data-v-71a73cda=""
                    >
                      <div class="paragraph-hyvQJfY5RlH text-output cparagraph-hyvQJfY5RlH none noBorder radius0 none">
                        <div>
                          <p>
                            Cookies are files with a small amount of data
                            commonly used as a unique anonymous identifier.
                            These are sent to your browser from the website you
                            visit and stored on your computer’s hard drive.
                          </p>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-0bd9c52 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="0bd9c52"
          data-element_type="section"
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-bdf8c65"
              data-id="bdf8c65"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-409195e elementor-widget elementor-widget-heading"
                  data-id="409195e"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h2 class="elementor-heading-title elementor-size-default">
                      Data Controller
                    </h2>{" "}
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-6f14752 elementor-widget elementor-widget-text-editor"
                  data-id="6f14752"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div class="elementor-widget-container">
                    <div
                      id="paragraph-b2Cjm7NKC2E"
                      class="c-paragraph c-wrapper"
                      data-v-71a73cda=""
                    >
                      <div class="paragraph-b2Cjm7NKC2E text-output cparagraph-b2Cjm7NKC2E none noBorder radius0 none">
                        <div>
                          <p>
                            Data Controller pertains to the legal entity tho
                            controls your information while participating in the
                            Service. We are a Data Controller of your personal
                            information. We determine the purposes for which it
                            will be processed and how it’s done in an effective
                            manner that ensures compliance with all relevant
                            laws while still maintaining the trust of our users.
                          </p>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-4592f20 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="4592f20"
          data-element_type="section"
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-9aebb9e"
              data-id="9aebb9e"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-89e196a elementor-widget elementor-widget-heading"
                  data-id="89e196a"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h2 class="elementor-heading-title elementor-size-default">
                      Data Processors (or Service Providers)
                    </h2>{" "}
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-6e964af elementor-widget elementor-widget-text-editor"
                  data-id="6e964af"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div class="elementor-widget-container">
                    <div
                      id="paragraph-rTGCU5NEHO-"
                      class="c-paragraph c-wrapper"
                      data-v-71a73cda=""
                    >
                      <div class="paragraph-rTGCU5NEHO- text-output cparagraph-rTGCU5NEHO- none noBorder radius0 none">
                        <div>
                          <p>
                            The Data Processor (or Service Provider) is any
                            natural or legal person who processes data on behalf
                            of the Data Controller. We may use various service
                            providers to process your information more
                            effectively.
                          </p>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-8ce3abf elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="8ce3abf"
          data-element_type="section"
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-6474b9e"
              data-id="6474b9e"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-bcf862a elementor-widget elementor-widget-heading"
                  data-id="bcf862a"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h2 class="elementor-heading-title elementor-size-default">
                      Data Subject (or User)
                    </h2>{" "}
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-dbc6aa0 elementor-widget elementor-widget-text-editor"
                  data-id="dbc6aa0"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div class="elementor-widget-container">
                    <div
                      id="section-F1M0hpSHnl"
                      class="fullSection none noBorder radius0 none c-section c-wrapper section-F1M0hpSHnl"
                      data-v-71a73cda=""
                    >
                      <div class="inner" data-v-71a73cda="">
                        <div
                          id="row-WPHSSn-ccn_"
                          class="row-align-center none noBorder radius0 none c-row c-wrapper row-WPHSSn-ccn_"
                          data-v-71a73cda=""
                        >
                          <div class="inner" data-v-71a73cda="">
                            <div
                              id="col-0APK0YXR6y-"
                              class="c-column c-wrapper col-0APK0YXR6y-"
                              data-v-71a73cda=""
                            >
                              <div
                                class="inner none noBorder radius0 none bg bgCover vertical"
                                data-v-71a73cda=""
                              >
                                <div
                                  id="paragraph--qK-ozkykkM"
                                  class="c-paragraph c-wrapper"
                                  data-v-71a73cda=""
                                >
                                  <div class="paragraph--qK-ozkykkM text-output cparagraph--qK-ozkykkM none noBorder radius0 none">
                                    <div>
                                      <p>
                                        Data Subjects are any individuals who
                                        use our Service and whose Personal Data
                                        we collect.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-0115e02 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="0115e02"
          data-element_type="section"
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-aff5f53"
              data-id="aff5f53"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-7c3327a elementor-widget elementor-widget-heading"
                  data-id="7c3327a"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h1 class="elementor-heading-title elementor-size-default">
                      Information Collection and Use
                    </h1>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-f6f6cee elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="f6f6cee"
          data-element_type="section"
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-84ce825"
              data-id="84ce825"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-1ca2b77 elementor-widget elementor-widget-heading"
                  data-id="1ca2b77"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h2 class="elementor-heading-title elementor-size-default">
                      Personal Data
                    </h2>{" "}
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-0a096d9 elementor-widget elementor-widget-text-editor"
                  data-id="0a096d9"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div class="elementor-widget-container">
                    <div
                      id="section-F1M0hpSHnl"
                      class="fullSection none noBorder radius0 none c-section c-wrapper section-F1M0hpSHnl"
                      data-v-71a73cda=""
                    >
                      <div class="inner" data-v-71a73cda="">
                        <div
                          id="row-WPHSSn-ccn_"
                          class="row-align-center none noBorder radius0 none c-row c-wrapper row-WPHSSn-ccn_"
                          data-v-71a73cda=""
                        >
                          <div class="inner" data-v-71a73cda="">
                            <div
                              id="col-0APK0YXR6y-"
                              class="c-column c-wrapper col-0APK0YXR6y-"
                              data-v-71a73cda=""
                            >
                              <div
                                class="inner none noBorder radius0 none bg bgCover vertical"
                                data-v-71a73cda=""
                              >
                                <div
                                  id="paragraph--qK-ozkykkM"
                                  class="c-paragraph c-wrapper"
                                  data-v-71a73cda=""
                                >
                                  <div class="paragraph--qK-ozkykkM text-output cparagraph--qK-ozkykkM none noBorder radius0 none">
                                    <div
                                      id="paragraph-_zQnsrQB9jH"
                                      class="c-paragraph c-wrapper"
                                      data-v-71a73cda=""
                                    >
                                      <div class="paragraph-_zQnsrQB9jH text-output cparagraph-_zQnsrQB9jH none noBorder radius0 none">
                                        <div>
                                          <p>
                                            For a better experience, while using
                                            our Service, we may require you to
                                            provide us with certain personally
                                            identifiable information, including
                                            but not limited to your name, phone
                                            number, and postal address. The
                                            information that we collect will be
                                            used to contact or identify you.
                                          </p>
                                          <p>
                                            We will only use your Personal Data
                                            to contact you with newsletters,
                                            marketing or promotional materials,
                                            and other information that may be
                                            interesting. You can opt out by
                                            following instructions in any email
                                            we send — however, this means
                                            unsubscribing from all emails which
                                            could contain important updates.
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-e6895a8 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="e6895a8"
          data-element_type="section"
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-865cad9"
              data-id="865cad9"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-4236f04 elementor-widget elementor-widget-heading"
                  data-id="4236f04"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h2 class="elementor-heading-title elementor-size-default">
                      Usage Data
                    </h2>{" "}
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-00445d8 elementor-widget elementor-widget-text-editor"
                  data-id="00445d8"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div class="elementor-widget-container">
                    <div
                      id="section-F1M0hpSHnl"
                      class="fullSection none noBorder radius0 none c-section c-wrapper section-F1M0hpSHnl"
                      data-v-71a73cda=""
                    >
                      <div class="inner" data-v-71a73cda="">
                        <div
                          id="row-WPHSSn-ccn_"
                          class="row-align-center none noBorder radius0 none c-row c-wrapper row-WPHSSn-ccn_"
                          data-v-71a73cda=""
                        >
                          <div class="inner" data-v-71a73cda="">
                            <div
                              id="col-0APK0YXR6y-"
                              class="c-column c-wrapper col-0APK0YXR6y-"
                              data-v-71a73cda=""
                            >
                              <div
                                class="inner none noBorder radius0 none bg bgCover vertical"
                                data-v-71a73cda=""
                              >
                                <div
                                  id="paragraph--qK-ozkykkM"
                                  class="c-paragraph c-wrapper"
                                  data-v-71a73cda=""
                                >
                                  <div class="paragraph--qK-ozkykkM text-output cparagraph--qK-ozkykkM none noBorder radius0 none">
                                    <div
                                      id="paragraph-x_r3ZBjlYi-"
                                      class="c-paragraph c-wrapper"
                                      data-v-71a73cda=""
                                    >
                                      <div class="paragraph-x_r3ZBjlYi- text-output cparagraph-x_r3ZBjlYi- none noBorder radius0 none">
                                        <div
                                          id="paragraph-x_r3ZBjlYi-"
                                          class="c-paragraph c-wrapper"
                                          data-v-71a73cda=""
                                        >
                                          <div class="paragraph-x_r3ZBjlYi- text-output cparagraph-x_r3ZBjlYi- none noBorder radius0 none">
                                            <div>
                                              <p>
                                                We want to inform you that
                                                whenever you visit our Service,
                                                we collect information that your
                                                browser sends to us called Usage
                                                Data. This Usage Data may
                                                include information such as your
                                                computer’s Internet Protocol
                                                (“IP”) address, browser version,
                                                pages of our Service that you
                                                visit, the time and date of your
                                                visit, the time spent on those
                                                pages, and other statistics.
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-8edaf68 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="8edaf68"
          data-element_type="section"
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-df1c053"
              data-id="df1c053"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-02925ea elementor-widget elementor-widget-heading"
                  data-id="02925ea"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h2 class="elementor-heading-title elementor-size-default">
                      Location Data
                    </h2>{" "}
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-d9e6900 elementor-widget elementor-widget-text-editor"
                  data-id="d9e6900"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div class="elementor-widget-container">
                    <div
                      id="section-F1M0hpSHnl"
                      class="fullSection none noBorder radius0 none c-section c-wrapper section-F1M0hpSHnl"
                      data-v-71a73cda=""
                    >
                      <div class="inner" data-v-71a73cda="">
                        <div
                          id="row-WPHSSn-ccn_"
                          class="row-align-center none noBorder radius0 none c-row c-wrapper row-WPHSSn-ccn_"
                          data-v-71a73cda=""
                        >
                          <div class="inner" data-v-71a73cda="">
                            <div
                              id="col-0APK0YXR6y-"
                              class="c-column c-wrapper col-0APK0YXR6y-"
                              data-v-71a73cda=""
                            >
                              <div
                                class="inner none noBorder radius0 none bg bgCover vertical"
                                data-v-71a73cda=""
                              >
                                <div
                                  id="paragraph--qK-ozkykkM"
                                  class="c-paragraph c-wrapper"
                                  data-v-71a73cda=""
                                >
                                  <div class="paragraph--qK-ozkykkM text-output cparagraph--qK-ozkykkM none noBorder radius0 none">
                                    <div
                                      id="paragraph-35d0Kobm4oQ"
                                      class="c-paragraph c-wrapper"
                                      data-v-71a73cda=""
                                    >
                                      <div class="paragraph-35d0Kobm4oQ text-output cparagraph-35d0Kobm4oQ none noBorder radius0 none">
                                        <div>
                                          <p>
                                            Location data is used to provide
                                            features of our service and improve
                                            and customize the way in which we
                                            offer it. You control when your
                                            location services are activated or
                                            deactivated by using settings on any
                                            device you use for internet browsing
                                            with us — including mobile devices.
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-23017d6 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="23017d6"
          data-element_type="section"
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-de03696"
              data-id="de03696"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-1a39adc elementor-widget elementor-widget-heading"
                  data-id="1a39adc"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h2 class="elementor-heading-title elementor-size-default">
                      Cookie Data
                    </h2>{" "}
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-59f2c33 elementor-widget elementor-widget-text-editor"
                  data-id="59f2c33"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div class="elementor-widget-container">
                    <div
                      id="section-F1M0hpSHnl"
                      class="fullSection none noBorder radius0 none c-section c-wrapper section-F1M0hpSHnl"
                      data-v-71a73cda=""
                    >
                      <div class="inner" data-v-71a73cda="">
                        <div
                          id="row-WPHSSn-ccn_"
                          class="row-align-center none noBorder radius0 none c-row c-wrapper row-WPHSSn-ccn_"
                          data-v-71a73cda=""
                        >
                          <div class="inner" data-v-71a73cda="">
                            <div
                              id="col-0APK0YXR6y-"
                              class="c-column c-wrapper col-0APK0YXR6y-"
                              data-v-71a73cda=""
                            >
                              <div
                                class="inner none noBorder radius0 none bg bgCover vertical"
                                data-v-71a73cda=""
                              >
                                <div
                                  id="paragraph--qK-ozkykkM"
                                  class="c-paragraph c-wrapper"
                                  data-v-71a73cda=""
                                >
                                  <div class="paragraph--qK-ozkykkM text-output cparagraph--qK-ozkykkM none noBorder radius0 none">
                                    <div
                                      id="paragraph-NSiSWfR60z8"
                                      class="c-paragraph c-wrapper"
                                      data-v-71a73cda=""
                                    >
                                      <div class="paragraph-NSiSWfR60z8 text-output cparagraph-NSiSWfR60z8 none noBorder radius0 none">
                                        <div>
                                          <p>
                                            Our website uses Cookies to collect
                                            information and to improve our
                                            Service. You can either accept or
                                            refuse these cookies and know when a
                                            cookie is being sent to your
                                            computer. If you choose to refuse
                                            our cookies, you may not be able to
                                            use some portions of our Service.
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-28b94a7 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="28b94a7"
          data-element_type="section"
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-d57fe96"
              data-id="d57fe96"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-1d1b81c elementor-widget elementor-widget-heading"
                  data-id="1d1b81c"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h2 class="elementor-heading-title elementor-size-default">
                      Examples of Cookies we use include:
                    </h2>{" "}
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-de65602 elementor-widget elementor-widget-text-editor"
                  data-id="de65602"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div class="elementor-widget-container">
                    <div
                      id="section-F1M0hpSHnl"
                      class="fullSection none noBorder radius0 none c-section c-wrapper section-F1M0hpSHnl"
                      data-v-71a73cda=""
                    >
                      <div class="inner" data-v-71a73cda="">
                        <div
                          id="row-WPHSSn-ccn_"
                          class="row-align-center none noBorder radius0 none c-row c-wrapper row-WPHSSn-ccn_"
                          data-v-71a73cda=""
                        >
                          <div class="inner" data-v-71a73cda="">
                            <div
                              id="col-0APK0YXR6y-"
                              class="c-column c-wrapper col-0APK0YXR6y-"
                              data-v-71a73cda=""
                            >
                              <div
                                class="inner none noBorder radius0 none bg bgCover vertical"
                                data-v-71a73cda=""
                              >
                                <div
                                  id="paragraph--qK-ozkykkM"
                                  class="c-paragraph c-wrapper"
                                  data-v-71a73cda=""
                                >
                                  <div class="paragraph--qK-ozkykkM text-output cparagraph--qK-ozkykkM none noBorder radius0 none">
                                    <ul>
                                      <li>
                                        <strong>Session Cookies —&nbsp;</strong>
                                        allows for Service operation
                                      </li>
                                    </ul>
                                    <ul>
                                      <li>
                                        <strong>
                                          Preference Cookies —&nbsp;
                                        </strong>
                                        allows for remembering your preferences
                                        and settings
                                      </li>
                                    </ul>
                                    <ul>
                                      <li>
                                        <strong>Security Cookies —</strong>
                                        &nbsp;for security purposes
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-8d2e0aa elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="8d2e0aa"
          data-element_type="section"
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-7f689fe"
              data-id="7f689fe"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-c12e366 elementor-widget elementor-widget-heading"
                  data-id="c12e366"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h2 class="elementor-heading-title elementor-size-default">
                      Service Providers
                    </h2>{" "}
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-8971986 elementor-widget elementor-widget-text-editor"
                  data-id="8971986"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div class="elementor-widget-container">
                    <div
                      id="section-F1M0hpSHnl"
                      class="fullSection none noBorder radius0 none c-section c-wrapper section-F1M0hpSHnl"
                      data-v-71a73cda=""
                    >
                      <div class="inner" data-v-71a73cda="">
                        <div
                          id="row-WPHSSn-ccn_"
                          class="row-align-center none noBorder radius0 none c-row c-wrapper row-WPHSSn-ccn_"
                          data-v-71a73cda=""
                        >
                          <div class="inner" data-v-71a73cda="">
                            <div
                              id="col-0APK0YXR6y-"
                              class="c-column c-wrapper col-0APK0YXR6y-"
                              data-v-71a73cda=""
                            >
                              <div
                                class="inner none noBorder radius0 none bg bgCover vertical"
                                data-v-71a73cda=""
                              >
                                <div
                                  id="paragraph--qK-ozkykkM"
                                  class="c-paragraph c-wrapper"
                                  data-v-71a73cda=""
                                >
                                  <div class="paragraph--qK-ozkykkM text-output cparagraph--qK-ozkykkM none noBorder radius0 none">
                                    <div
                                      id="paragraph-p7PAGI5Gr3n"
                                      class="c-paragraph c-wrapper"
                                      data-v-71a73cda=""
                                    >
                                      <div class="paragraph-p7PAGI5Gr3n text-output cparagraph-p7PAGI5Gr3n none noBorder radius0 none">
                                        <div>
                                          <p>
                                            We may employ third-party companies
                                            and individuals due to the following
                                            reasons:
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      id="bulletList-ixTtFHFoohq"
                                      class="c-bullet-list c-wrapper"
                                      data-v-71a73cda=""
                                    >
                                      <div class="bulletList-ixTtFHFoohq text-output cbulletList-ixTtFHFoohq none noBorder radius0 none">
                                        <div>
                                          <ul>
                                            <li>To facilitate our Service;</li>
                                          </ul>
                                          <ul>
                                            <li>
                                              To provide the Service on our
                                              behalf;
                                            </li>
                                          </ul>
                                          <ul>
                                            <li>
                                              To perform Service-related
                                              services; or
                                            </li>
                                          </ul>
                                          <ul>
                                            <li>
                                              To assist us in analyzing how our
                                              Service is used.
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      id="paragraph-50dGagqrarn"
                                      class="c-paragraph c-wrapper"
                                      data-v-71a73cda=""
                                    >
                                      <div class="paragraph-50dGagqrarn text-output cparagraph-50dGagqrarn none noBorder radius0 none">
                                        <div>
                                          <p>
                                            We want to inform our Service users
                                            that these third parties have access
                                            to your Personal Data. The reason is
                                            to perform the tasks assigned to
                                            them on our behalf. However, they
                                            are obligated not to disclose or use
                                            the information for any other
                                            purpose.
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-2dbbf57 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="2dbbf57"
          data-element_type="section"
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-a5626d2"
              data-id="a5626d2"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-750131f elementor-widget elementor-widget-heading"
                  data-id="750131f"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h2 class="elementor-heading-title elementor-size-default">
                      Security
                    </h2>{" "}
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-8bb9ccb elementor-widget elementor-widget-text-editor"
                  data-id="8bb9ccb"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div class="elementor-widget-container">
                    <div
                      id="section-F1M0hpSHnl"
                      class="fullSection none noBorder radius0 none c-section c-wrapper section-F1M0hpSHnl"
                      data-v-71a73cda=""
                    >
                      <div class="inner" data-v-71a73cda="">
                        <div
                          id="row-WPHSSn-ccn_"
                          class="row-align-center none noBorder radius0 none c-row c-wrapper row-WPHSSn-ccn_"
                          data-v-71a73cda=""
                        >
                          <div class="inner" data-v-71a73cda="">
                            <div
                              id="col-0APK0YXR6y-"
                              class="c-column c-wrapper col-0APK0YXR6y-"
                              data-v-71a73cda=""
                            >
                              <div
                                class="inner none noBorder radius0 none bg bgCover vertical"
                                data-v-71a73cda=""
                              >
                                <div
                                  id="paragraph--qK-ozkykkM"
                                  class="c-paragraph c-wrapper"
                                  data-v-71a73cda=""
                                >
                                  <div class="paragraph--qK-ozkykkM text-output cparagraph--qK-ozkykkM none noBorder radius0 none">
                                    <div
                                      id="section-4msokCEGiW"
                                      class="fullSection none noBorder radius0 none c-section c-wrapper section-4msokCEGiW"
                                      data-v-71a73cda=""
                                    >
                                      <div class="inner" data-v-71a73cda="">
                                        <div
                                          id="row-DxkLsnuB0a0"
                                          class="row-align-center none noBorder radius0 none c-row c-wrapper row-DxkLsnuB0a0"
                                          data-v-71a73cda=""
                                        >
                                          <div class="inner" data-v-71a73cda="">
                                            <div
                                              id="col-qMcON4p7TFm"
                                              class="c-column c-wrapper col-qMcON4p7TFm"
                                              data-v-71a73cda=""
                                            >
                                              <div
                                                class="inner none noBorder radius0 none bg bgCover vertical"
                                                data-v-71a73cda=""
                                              >
                                                <div
                                                  id="paragraph-pWPvSwpenR0"
                                                  class="c-paragraph c-wrapper"
                                                  data-v-71a73cda=""
                                                >
                                                  <div class="paragraph-pWPvSwpenR0 text-output cparagraph-pWPvSwpenR0 none noBorder radius0 none">
                                                    <div>
                                                      <p>
                                                        We value your trust in
                                                        providing us with your
                                                        Personal Data. Thus, we
                                                        strive to use
                                                        commercially acceptable
                                                        means of protecting it.
                                                        But remember that no
                                                        method of transmission
                                                        over the internet or
                                                        method of electronic
                                                        storage is 100% secure
                                                        and reliable, and we
                                                        cannot guarantee its
                                                        absolute security.
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-ecfa179 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="ecfa179"
          data-element_type="section"
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-a52097c"
              data-id="a52097c"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-3cb978e elementor-widget elementor-widget-heading"
                  data-id="3cb978e"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h2 class="elementor-heading-title elementor-size-default">
                      Use of Data
                    </h2>{" "}
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-d11daa9 elementor-widget elementor-widget-text-editor"
                  data-id="d11daa9"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div class="elementor-widget-container">
                    <div
                      id="section-F1M0hpSHnl"
                      class="fullSection none noBorder radius0 none c-section c-wrapper section-F1M0hpSHnl"
                      data-v-71a73cda=""
                    >
                      <div class="inner" data-v-71a73cda="">
                        <div
                          id="row-WPHSSn-ccn_"
                          class="row-align-center none noBorder radius0 none c-row c-wrapper row-WPHSSn-ccn_"
                          data-v-71a73cda=""
                        >
                          <div class="inner" data-v-71a73cda="">
                            <div
                              id="col-0APK0YXR6y-"
                              class="c-column c-wrapper col-0APK0YXR6y-"
                              data-v-71a73cda=""
                            >
                              <div
                                class="inner none noBorder radius0 none bg bgCover vertical"
                                data-v-71a73cda=""
                              >
                                <div
                                  id="paragraph--qK-ozkykkM"
                                  class="c-paragraph c-wrapper"
                                  data-v-71a73cda=""
                                >
                                  <div class="paragraph--qK-ozkykkM text-output cparagraph--qK-ozkykkM none noBorder radius0 none">
                                    <div
                                      id="paragraph-F-iU0ZNlrJL"
                                      class="c-paragraph c-wrapper"
                                      data-v-71a73cda=""
                                    >
                                      <div class="paragraph-F-iU0ZNlrJL text-output cparagraph-F-iU0ZNlrJL none noBorder radius0 none">
                                        <div>
                                          <p>
                                            Intermedia Marketing Group LLC
                                            collects your browsing data so to
                                            provide you with various services.
                                            The collected information is used
                                            for several purposes, including:
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      id="bulletList-Qqe6FCSHm3h"
                                      class="c-bullet-list c-wrapper"
                                      data-v-71a73cda=""
                                    >
                                      <div class="bulletList-Qqe6FCSHm3h text-output cbulletList-Qqe6FCSHm3h none noBorder radius0 none">
                                        <div>
                                          <ul>
                                            <li>
                                              To notify users about changes in
                                              service offerings or adjustments
                                              based on user feedback
                                            </li>
                                          </ul>
                                          <ul>
                                            <li>
                                              Maintain an effective customer
                                              experience by gathering analysis
                                              of how people are using the
                                              Service’s features when allowed to
                                              participate via email newsletter
                                              subscription process (if
                                              applicable)
                                            </li>
                                          </ul>
                                          <ul>
                                            <li>
                                              Monitoring technical issues
                                              affecting performance across their
                                              network which may lead to
                                              providing news updates/special
                                              offers
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-3adf41b elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="3adf41b"
          data-element_type="section"
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-b6a95b7"
              data-id="b6a95b7"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-682545c elementor-widget elementor-widget-heading"
                  data-id="682545c"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h2 class="elementor-heading-title elementor-size-default">
                      Processing Personal Data under the General Data Protection
                      Regulation (GDPR)
                    </h2>{" "}
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-7971074 elementor-widget elementor-widget-text-editor"
                  data-id="7971074"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div class="elementor-widget-container">
                    <div
                      id="section-F1M0hpSHnl"
                      class="fullSection none noBorder radius0 none c-section c-wrapper section-F1M0hpSHnl"
                      data-v-71a73cda=""
                    >
                      <div class="inner" data-v-71a73cda="">
                        <div
                          id="row-WPHSSn-ccn_"
                          class="row-align-center none noBorder radius0 none c-row c-wrapper row-WPHSSn-ccn_"
                          data-v-71a73cda=""
                        >
                          <div class="inner" data-v-71a73cda="">
                            <div
                              id="col-0APK0YXR6y-"
                              class="c-column c-wrapper col-0APK0YXR6y-"
                              data-v-71a73cda=""
                            >
                              <div
                                class="inner none noBorder radius0 none bg bgCover vertical"
                                data-v-71a73cda=""
                              >
                                <div
                                  id="paragraph--qK-ozkykkM"
                                  class="c-paragraph c-wrapper"
                                  data-v-71a73cda=""
                                >
                                  <div class="paragraph--qK-ozkykkM text-output cparagraph--qK-ozkykkM none noBorder radius0 none">
                                    <div
                                      id="paragraph-A7lgFDsEo-h"
                                      class="c-paragraph c-wrapper"
                                      data-v-71a73cda=""
                                    >
                                      <div class="paragraph-A7lgFDsEo-h text-output cparagraph-A7lgFDsEo-h none noBorder radius0 none">
                                        <div>
                                          <p>
                                            If you are from the European
                                            Economic Area (EEA), Intermedia
                                            Marketing Group LLC may collect and
                                            use the Personal Data described in
                                            this Privacy Policy depending on the
                                            specific context in which it is
                                            collected.
                                          </p>
                                          <p>&nbsp;</p>
                                          <p>
                                            Intermedia Marketing Group LLC may
                                            process your Personal Data due to:
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      id="bulletList-XrLlfCmC_4i"
                                      class="c-bullet-list c-wrapper"
                                      data-v-71a73cda=""
                                    >
                                      <div class="bulletList-XrLlfCmC_4i text-output cbulletList-XrLlfCmC_4i none noBorder radius0 none">
                                        <div>
                                          <ul>
                                            <li>
                                              A contract needing to be performed
                                              and fulfilled
                                            </li>
                                          </ul>
                                          <ul>
                                            <li>
                                              You have given us express
                                              permission to do so
                                            </li>
                                          </ul>
                                          <ul>
                                            <li>
                                              Processing is in our legitimate
                                              interests and does not override
                                              your rights
                                            </li>
                                          </ul>
                                          <ul>
                                            <li>Compliance with the law</li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-fd68828 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="fd68828"
          data-element_type="section"
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-b245f31"
              data-id="b245f31"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-cde2d50 elementor-widget elementor-widget-heading"
                  data-id="cde2d50"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h2 class="elementor-heading-title elementor-size-default">
                      Retention of Data
                    </h2>{" "}
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-7f58180 elementor-widget elementor-widget-text-editor"
                  data-id="7f58180"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div class="elementor-widget-container">
                    <div
                      id="section-F1M0hpSHnl"
                      class="fullSection none noBorder radius0 none c-section c-wrapper section-F1M0hpSHnl"
                      data-v-71a73cda=""
                    >
                      <div class="inner" data-v-71a73cda="">
                        <div
                          id="row-WPHSSn-ccn_"
                          class="row-align-center none noBorder radius0 none c-row c-wrapper row-WPHSSn-ccn_"
                          data-v-71a73cda=""
                        >
                          <div class="inner" data-v-71a73cda="">
                            <div
                              id="col-0APK0YXR6y-"
                              class="c-column c-wrapper col-0APK0YXR6y-"
                              data-v-71a73cda=""
                            >
                              <div
                                class="inner none noBorder radius0 none bg bgCover vertical"
                                data-v-71a73cda=""
                              >
                                <div
                                  id="paragraph--qK-ozkykkM"
                                  class="c-paragraph c-wrapper"
                                  data-v-71a73cda=""
                                >
                                  <div class="paragraph--qK-ozkykkM text-output cparagraph--qK-ozkykkM none noBorder radius0 none">
                                    <p>
                                      Intermedia Marketing Group LLC will retain
                                      your Personal Data for as long as
                                      necessary for the sole purposes set forth
                                      within this Privacy Policy. We will retain
                                      and use your Personal Data only to comply
                                      with legal obligations, resolve disputes,
                                      and enforce our legal agreements and
                                      policies.
                                    </p>
                                    <p>
                                      Intermedia Marketing Group LLC will also
                                      retain Usage Data for internal analysis,
                                      such as when needed to strengthen the
                                      security or improve the functionality of
                                      our Service. We will also retain this data
                                      as legally obligated to do so.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-84d548b elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="84d548b"
          data-element_type="section"
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-5fef98f"
              data-id="5fef98f"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-5562011 elementor-widget elementor-widget-heading"
                  data-id="5562011"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h2 class="elementor-heading-title elementor-size-default">
                      Transfer of Data
                    </h2>{" "}
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-11d7083 elementor-widget elementor-widget-text-editor"
                  data-id="11d7083"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div class="elementor-widget-container">
                    <div
                      id="section-F1M0hpSHnl"
                      class="fullSection none noBorder radius0 none c-section c-wrapper section-F1M0hpSHnl"
                      data-v-71a73cda=""
                    >
                      <div class="inner" data-v-71a73cda="">
                        <div
                          id="row-WPHSSn-ccn_"
                          class="row-align-center none noBorder radius0 none c-row c-wrapper row-WPHSSn-ccn_"
                          data-v-71a73cda=""
                        >
                          <div class="inner" data-v-71a73cda="">
                            <div
                              id="col-0APK0YXR6y-"
                              class="c-column c-wrapper col-0APK0YXR6y-"
                              data-v-71a73cda=""
                            >
                              <div
                                class="inner none noBorder radius0 none bg bgCover vertical"
                                data-v-71a73cda=""
                              >
                                <div
                                  id="paragraph--qK-ozkykkM"
                                  class="c-paragraph c-wrapper"
                                  data-v-71a73cda=""
                                >
                                  <div class="paragraph--qK-ozkykkM text-output cparagraph--qK-ozkykkM none noBorder radius0 none">
                                    <p>
                                      Your information, including your Personal
                                      Data, may be transferred to and maintained
                                      on computers located outside of your
                                      state, province, country, or other
                                      governmental jurisdiction where data
                                      protection laws may differ from those in
                                      your jurisdiction.
                                    </p>
                                    <p>
                                      By providing us with any information you
                                      choose to share, including Personal Data
                                      like name and address or phone number, you
                                      give Intermedia Marketing Group LLC
                                      permission to transfer that data across
                                      borders within the United States (and
                                      other countries), which is processed
                                      there.
                                    </p>
                                    <p>
                                      You agree this Privacy Policy followed
                                      means to accept all obligations imposed
                                      upon its use at destinations outside the
                                      United States.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-b577e5e elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="b577e5e"
          data-element_type="section"
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ce3a2cb"
              data-id="ce3a2cb"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-a677a7b elementor-widget elementor-widget-heading"
                  data-id="a677a7b"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h1 class="elementor-heading-title elementor-size-default">
                      Disclosure of Data
                    </h1>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-12796bf elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="12796bf"
          data-element_type="section"
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-63dd54a"
              data-id="63dd54a"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-9b048fd elementor-widget elementor-widget-heading"
                  data-id="9b048fd"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h2 class="elementor-heading-title elementor-size-default">
                      Disclosure for Law Enforcement
                    </h2>{" "}
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-42aeff7 elementor-widget elementor-widget-text-editor"
                  data-id="42aeff7"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div class="elementor-widget-container">
                    <div
                      id="section-F1M0hpSHnl"
                      class="fullSection none noBorder radius0 none c-section c-wrapper section-F1M0hpSHnl"
                      data-v-71a73cda=""
                    >
                      <div class="inner" data-v-71a73cda="">
                        <div
                          id="row-WPHSSn-ccn_"
                          class="row-align-center none noBorder radius0 none c-row c-wrapper row-WPHSSn-ccn_"
                          data-v-71a73cda=""
                        >
                          <div class="inner" data-v-71a73cda="">
                            <div
                              id="col-0APK0YXR6y-"
                              class="c-column c-wrapper col-0APK0YXR6y-"
                              data-v-71a73cda=""
                            >
                              <div
                                class="inner none noBorder radius0 none bg bgCover vertical"
                                data-v-71a73cda=""
                              >
                                <div
                                  id="paragraph--qK-ozkykkM"
                                  class="c-paragraph c-wrapper"
                                  data-v-71a73cda=""
                                >
                                  <div class="paragraph--qK-ozkykkM text-output cparagraph--qK-ozkykkM none noBorder radius0 none">
                                    <div
                                      id="paragraph-HzBPMXMNqI4"
                                      class="c-paragraph c-wrapper"
                                      data-v-71a73cda=""
                                    >
                                      <div class="paragraph-HzBPMXMNqI4 text-output cparagraph-HzBPMXMNqI4 none noBorder radius0 none">
                                        <div>
                                          <p>
                                            Intermedia Marketing Group LLC has a
                                            responsibility to protect your
                                            Personal Data. We will never release
                                            any of it without complying with all
                                            applicable laws and responding
                                            properly if asked by law enforcement
                                            or other authorities.
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-e0688c7 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="e0688c7"
          data-element_type="section"
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-e3a4dd3"
              data-id="e3a4dd3"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-9f05df3 elementor-widget elementor-widget-heading"
                  data-id="9f05df3"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h2 class="elementor-heading-title elementor-size-default">
                      Legal Requirements
                    </h2>{" "}
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-ad2131a elementor-widget elementor-widget-text-editor"
                  data-id="ad2131a"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div class="elementor-widget-container">
                    <div
                      id="section-F1M0hpSHnl"
                      class="fullSection none noBorder radius0 none c-section c-wrapper section-F1M0hpSHnl"
                      data-v-71a73cda=""
                    >
                      <div class="inner" data-v-71a73cda="">
                        <div
                          id="row-WPHSSn-ccn_"
                          class="row-align-center none noBorder radius0 none c-row c-wrapper row-WPHSSn-ccn_"
                          data-v-71a73cda=""
                        >
                          <div class="inner" data-v-71a73cda="">
                            <div
                              id="col-0APK0YXR6y-"
                              class="c-column c-wrapper col-0APK0YXR6y-"
                              data-v-71a73cda=""
                            >
                              <div
                                class="inner none noBorder radius0 none bg bgCover vertical"
                                data-v-71a73cda=""
                              >
                                <div
                                  id="paragraph--qK-ozkykkM"
                                  class="c-paragraph c-wrapper"
                                  data-v-71a73cda=""
                                >
                                  <div class="paragraph--qK-ozkykkM text-output cparagraph--qK-ozkykkM none noBorder radius0 none">
                                    <div
                                      id="paragraph-Vvgj4Skc3qX"
                                      class="c-paragraph c-wrapper"
                                      data-v-71a73cda=""
                                    >
                                      <div class="paragraph-Vvgj4Skc3qX text-output cparagraph-Vvgj4Skc3qX none noBorder radius0 none">
                                        <div>
                                          <p>
                                            Intermedia Marketing Group LLC may
                                            disclose your Personal Data in the
                                            good faith belief that such action
                                            is necessary to:
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      id="bulletList-TxQVYJEVFPs"
                                      class="c-bullet-list c-wrapper"
                                      data-v-71a73cda=""
                                    >
                                      <div class="bulletList-TxQVYJEVFPs text-output cbulletList-TxQVYJEVFPs none noBorder radius0 none">
                                        <div>
                                          <ul>
                                            <li>
                                              To notify users about changes in
                                              service offerings or adjustments
                                              based on user feedback
                                            </li>
                                          </ul>
                                          <ul>
                                            <li>
                                              Comply with a legal obligation
                                            </li>
                                          </ul>
                                          <ul>
                                            <li>
                                              Protect and defend the rights or
                                              property of Intermedia Marketing
                                              Group LLC
                                            </li>
                                          </ul>
                                          <ul>
                                            <li>
                                              Protect clients &amp; employees
                                              from any harm caused by their
                                              actions where it cannot be avoided
                                              through reasonable precautions
                                              taken beforehand
                                            </li>
                                          </ul>
                                          <ul>
                                            <li>
                                              Prevent or investigate potential
                                              wrongdoing in connection with the
                                              Service
                                            </li>
                                          </ul>
                                          <ul>
                                            <li>
                                              Protect the personal safety of
                                              users of the Service
                                            </li>
                                          </ul>
                                          <ul>
                                            <li>
                                              Protect against personal liability
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-c50865b elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="c50865b"
          data-element_type="section"
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-15d804c"
              data-id="15d804c"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-313b8fc elementor-widget elementor-widget-heading"
                  data-id="313b8fc"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h2 class="elementor-heading-title elementor-size-default">
                      Security of Data
                    </h2>{" "}
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-9658d16 elementor-widget elementor-widget-text-editor"
                  data-id="9658d16"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div class="elementor-widget-container">
                    <div
                      id="section-F1M0hpSHnl"
                      class="fullSection none noBorder radius0 none c-section c-wrapper section-F1M0hpSHnl"
                      data-v-71a73cda=""
                    >
                      <div class="inner" data-v-71a73cda="">
                        <div
                          id="row-WPHSSn-ccn_"
                          class="row-align-center none noBorder radius0 none c-row c-wrapper row-WPHSSn-ccn_"
                          data-v-71a73cda=""
                        >
                          <div class="inner" data-v-71a73cda="">
                            <div
                              id="col-0APK0YXR6y-"
                              class="c-column c-wrapper col-0APK0YXR6y-"
                              data-v-71a73cda=""
                            >
                              <div
                                class="inner none noBorder radius0 none bg bgCover vertical"
                                data-v-71a73cda=""
                              >
                                <div
                                  id="paragraph--qK-ozkykkM"
                                  class="c-paragraph c-wrapper"
                                  data-v-71a73cda=""
                                >
                                  <div class="paragraph--qK-ozkykkM text-output cparagraph--qK-ozkykkM none noBorder radius0 none">
                                    <div
                                      id="section-CAAE7jWxDm"
                                      class="fullSection none noBorder radius0 none c-section c-wrapper section-CAAE7jWxDm"
                                      data-v-71a73cda=""
                                    >
                                      <div class="inner" data-v-71a73cda="">
                                        <div
                                          id="row-2JTEiD60Pg5"
                                          class="row-align-center none noBorder radius0 none c-row c-wrapper row-2JTEiD60Pg5"
                                          data-v-71a73cda=""
                                        >
                                          <div class="inner" data-v-71a73cda="">
                                            <div
                                              id="col-qAqB4eQuSOF"
                                              class="c-column c-wrapper col-qAqB4eQuSOF"
                                              data-v-71a73cda=""
                                            >
                                              <div
                                                class="inner none noBorder radius0 none bg bgCover vertical"
                                                data-v-71a73cda=""
                                              >
                                                <div
                                                  id="paragraph-uA21Ihcz33s"
                                                  class="c-paragraph c-wrapper"
                                                  data-v-71a73cda=""
                                                >
                                                  <div class="paragraph-uA21Ihcz33s text-output cparagraph-uA21Ihcz33s none noBorder radius0 none">
                                                    <div>
                                                      <p>
                                                        The security of your
                                                        data is a top priority
                                                        for us. We’ll do
                                                        everything we can to
                                                        protect it, but remember
                                                        that no electronic
                                                        storage media method
                                                        will ever be 100% secure
                                                        when sending information
                                                        over the internet.
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-dde838a elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="dde838a"
          data-element_type="section"
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-e8dd385"
              data-id="e8dd385"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-41dde6e elementor-widget elementor-widget-heading"
                  data-id="41dde6e"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h2 class="elementor-heading-title elementor-size-default">
                      Our Policy on “Do Not Track” Signals under the California
                      Online Protection Act
                    </h2>{" "}
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-5d0eb5e elementor-widget elementor-widget-text-editor"
                  data-id="5d0eb5e"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div class="elementor-widget-container">
                    <div
                      id="section-F1M0hpSHnl"
                      class="fullSection none noBorder radius0 none c-section c-wrapper section-F1M0hpSHnl"
                      data-v-71a73cda=""
                    >
                      <div class="inner" data-v-71a73cda="">
                        <div
                          id="row-WPHSSn-ccn_"
                          class="row-align-center none noBorder radius0 none c-row c-wrapper row-WPHSSn-ccn_"
                          data-v-71a73cda=""
                        >
                          <div class="inner" data-v-71a73cda="">
                            <div
                              id="col-0APK0YXR6y-"
                              class="c-column c-wrapper col-0APK0YXR6y-"
                              data-v-71a73cda=""
                            >
                              <div
                                class="inner none noBorder radius0 none bg bgCover vertical"
                                data-v-71a73cda=""
                              >
                                <div
                                  id="paragraph--qK-ozkykkM"
                                  class="c-paragraph c-wrapper"
                                  data-v-71a73cda=""
                                >
                                  <div class="paragraph--qK-ozkykkM text-output cparagraph--qK-ozkykkM none noBorder radius0 none">
                                    <p>
                                      We do not support Do Not Track (DNT). You
                                      can set this preference in your web
                                      browser to inform websites that they
                                      should not track your activity.
                                    </p>
                                    <p>
                                      You can enable or disable this feature
                                      through the preferences menu on any given
                                      website by adjusting the appropriate
                                      settings on your web browser.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-bffd9c8 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="bffd9c8"
          data-element_type="section"
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-044f663"
              data-id="044f663"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-61d673b elementor-widget elementor-widget-heading"
                  data-id="61d673b"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h2 class="elementor-heading-title elementor-size-default">
                      Your Data Protection Rights Under GDPR
                    </h2>{" "}
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-7e324a3 elementor-widget elementor-widget-text-editor"
                  data-id="7e324a3"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div class="elementor-widget-container">
                    <div
                      id="section-F1M0hpSHnl"
                      class="fullSection none noBorder radius0 none c-section c-wrapper section-F1M0hpSHnl"
                      data-v-71a73cda=""
                    >
                      <div class="inner" data-v-71a73cda="">
                        <div
                          id="row-WPHSSn-ccn_"
                          class="row-align-center none noBorder radius0 none c-row c-wrapper row-WPHSSn-ccn_"
                          data-v-71a73cda=""
                        >
                          <div class="inner" data-v-71a73cda="">
                            <div
                              id="col-0APK0YXR6y-"
                              class="c-column c-wrapper col-0APK0YXR6y-"
                              data-v-71a73cda=""
                            >
                              <div
                                class="inner none noBorder radius0 none bg bgCover vertical"
                                data-v-71a73cda=""
                              >
                                <div
                                  id="paragraph--qK-ozkykkM"
                                  class="c-paragraph c-wrapper"
                                  data-v-71a73cda=""
                                >
                                  <div class="paragraph--qK-ozkykkM text-output cparagraph--qK-ozkykkM none noBorder radius0 none">
                                    <div
                                      id="paragraph-z85IIvyo3zY"
                                      class="c-paragraph c-wrapper"
                                      data-v-71a73cda=""
                                    >
                                      <div class="paragraph-z85IIvyo3zY text-output cparagraph-z85IIvyo3zY none noBorder radius0 none">
                                        <div>
                                          <p>
                                            If you are a resident of the
                                            European Economic Area (EEA), then
                                            we aim to make certain that your
                                            Personal Data is processed fairly
                                            and in accordance with data
                                            protection laws.
                                          </p>
                                          <p>
                                            Should you request information about
                                            the Personal Data we hold about you
                                            or want it removed, please contact
                                            us so we may act accordingly.
                                          </p>
                                          <p>
                                            You may also have the following data
                                            protection rights:
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      id="bulletList-ZmlmUaHwr47"
                                      class="c-bullet-list c-wrapper"
                                      data-v-71a73cda=""
                                    >
                                      <div class="bulletList-ZmlmUaHwr47 text-output cbulletList-ZmlmUaHwr47 none noBorder radius0 none">
                                        <div>
                                          <ul>
                                            <li>
                                              <strong>
                                                The right to be informed —&nbsp;
                                              </strong>
                                              You have the right to be told how
                                              your Personal Data will be
                                              processed by the Data Controller.
                                              This is normally done through a
                                              privacy notice.
                                            </li>
                                          </ul>
                                          <ul>
                                            <li>
                                              <strong>
                                                The right to access your
                                                Personal Data —
                                              </strong>
                                              &nbsp;You have the right to ask
                                              the Data Controller whether they
                                              are processing your Personal Data,
                                              and if they are, you can request a
                                              copy of that personal information.
                                            </li>
                                          </ul>
                                          <ul>
                                            <li>
                                              <strong>
                                                The right of rectification —
                                              </strong>
                                              &nbsp;You have the right to ensure
                                              your information is rectified if
                                              it is found to be incomplete or
                                              inaccurate.
                                            </li>
                                          </ul>
                                          <ul>
                                            <li>
                                              <strong>
                                                The right to have your Personal
                                                Data erased —
                                              </strong>
                                              &nbsp;Also known as the “right to
                                              be forgotten” and, in certain
                                              circumstances, you can ask the
                                              Data Controller to erase your
                                              data.
                                            </li>
                                          </ul>
                                          <ul>
                                            <li>
                                              <strong>
                                                The right to withdraw consent
                                                —&nbsp;
                                              </strong>
                                              You have the right to withdraw
                                              your consent at any point in time
                                              when Intermedia Marketing Group
                                              LLC relies on your consent to
                                              process personal information.
                                            </li>
                                          </ul>
                                          <ul>
                                            <li>
                                              <strong>
                                                The right to restrict processing
                                                of your Personal Data —
                                              </strong>
                                              &nbsp;You have the right to ask
                                              the Data Controller to restrict or
                                              stop processing your Personal Data
                                              under certain circumstances.
                                            </li>
                                          </ul>
                                          <ul>
                                            <li>
                                              <strong>
                                                The right to data portability —
                                              </strong>
                                              &nbsp;You have the right, in
                                              certain circumstances, to ask the
                                              Data Controller for a copy of your
                                              data to re-use for your own
                                              purposes.
                                            </li>
                                          </ul>
                                          <ul>
                                            <li>
                                              <strong>
                                                The right to object —
                                              </strong>
                                              &nbsp;You have the right, in
                                              certain circumstances, to object
                                              to the Data Controller processing
                                              your data.
                                            </li>
                                          </ul>
                                          <ul>
                                            <li>
                                              <strong>
                                                Rights in relation to automated
                                                decision making and profiling —
                                              </strong>
                                              &nbsp;You have the right, in
                                              certain circumstances, to ask the
                                              Data Controller not to make
                                              decisions about you that are based
                                              solely on automated processing or
                                              profiling
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      id="paragraph-4J478VczcUm"
                                      class="c-paragraph c-wrapper"
                                      data-v-71a73cda=""
                                    >
                                      <div class="paragraph-4J478VczcUm text-output cparagraph-4J478VczcUm none noBorder radius0 none">
                                        <div>
                                          <p>
                                            Please note that to remain compliant
                                            with law enforcement, we may ask
                                            that you verify your identity before
                                            responding to or taking action on
                                            your request.
                                          </p>
                                          <p>
                                            You also have the right to file a
                                            complaint with a Data Protection
                                            Authority about our collection and
                                            use of your information. If you have
                                            questions or want more information,
                                            please contact your local European
                                            Economic Area (EEA) data protection
                                            authority.
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-f987302 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="f987302"
          data-element_type="section"
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-f9a7972"
              data-id="f9a7972"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-aa77c35 elementor-widget elementor-widget-heading"
                  data-id="aa77c35"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h2 class="elementor-heading-title elementor-size-default">
                      Service Providers
                    </h2>{" "}
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-387bc2d elementor-widget elementor-widget-text-editor"
                  data-id="387bc2d"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div class="elementor-widget-container">
                    <div
                      id="section-F1M0hpSHnl"
                      class="fullSection none noBorder radius0 none c-section c-wrapper section-F1M0hpSHnl"
                      data-v-71a73cda=""
                    >
                      <div class="inner" data-v-71a73cda="">
                        <div
                          id="row-WPHSSn-ccn_"
                          class="row-align-center none noBorder radius0 none c-row c-wrapper row-WPHSSn-ccn_"
                          data-v-71a73cda=""
                        >
                          <div class="inner" data-v-71a73cda="">
                            <div
                              id="col-0APK0YXR6y-"
                              class="c-column c-wrapper col-0APK0YXR6y-"
                              data-v-71a73cda=""
                            >
                              <div
                                class="inner none noBorder radius0 none bg bgCover vertical"
                                data-v-71a73cda=""
                              >
                                <div
                                  id="paragraph--qK-ozkykkM"
                                  class="c-paragraph c-wrapper"
                                  data-v-71a73cda=""
                                >
                                  <div class="paragraph--qK-ozkykkM text-output cparagraph--qK-ozkykkM none noBorder radius0 none">
                                    <p>
                                      We may employ third-party companies
                                      (Service Providers) to help us deliver our
                                      Service, such as by handling customer
                                      support or sending email newsletters about
                                      new services. These Service Providers have
                                      access to Personal Data but are prohibited
                                      from disclosing any information you shared
                                      with them unless necessary.
                                    </p>
                                    <p>
                                      Service Providers are only to perform
                                      tasks on our behalf and may not use or
                                      disclose your Personal Data for any other
                                      purpose.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-6f06c6b elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="6f06c6b"
          data-element_type="section"
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-f4ab412"
              data-id="f4ab412"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-d738569 elementor-widget elementor-widget-heading"
                  data-id="d738569"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h2 class="elementor-heading-title elementor-size-default">
                      Analytics
                    </h2>{" "}
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-c3903dc elementor-widget elementor-widget-text-editor"
                  data-id="c3903dc"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div class="elementor-widget-container">
                    <div
                      id="section-F1M0hpSHnl"
                      class="fullSection none noBorder radius0 none c-section c-wrapper section-F1M0hpSHnl"
                      data-v-71a73cda=""
                    >
                      <div class="inner" data-v-71a73cda="">
                        <div
                          id="row-WPHSSn-ccn_"
                          class="row-align-center none noBorder radius0 none c-row c-wrapper row-WPHSSn-ccn_"
                          data-v-71a73cda=""
                        >
                          <div class="inner" data-v-71a73cda="">
                            <div
                              id="col-0APK0YXR6y-"
                              class="c-column c-wrapper col-0APK0YXR6y-"
                              data-v-71a73cda=""
                            >
                              <div
                                class="inner none noBorder radius0 none bg bgCover vertical"
                                data-v-71a73cda=""
                              >
                                <div
                                  id="paragraph--qK-ozkykkM"
                                  class="c-paragraph c-wrapper"
                                  data-v-71a73cda=""
                                >
                                  <div class="paragraph--qK-ozkykkM text-output cparagraph--qK-ozkykkM none noBorder radius0 none">
                                    <div
                                      id="paragraph-Ae-sE_s9lS5"
                                      class="c-paragraph c-wrapper"
                                      data-v-71a73cda=""
                                    >
                                      <div class="paragraph-Ae-sE_s9lS5 text-output cparagraph-Ae-sE_s9lS5 none noBorder radius0 none">
                                        <div>
                                          <p>
                                            We may use third-party Service
                                            Providers to complete an analysis of
                                            the Service’s use and monitor user
                                            activity.
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-39de254 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="39de254"
          data-element_type="section"
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-de8e6b7"
              data-id="de8e6b7"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-f6d5b22 elementor-widget elementor-widget-heading"
                  data-id="f6d5b22"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h2 class="elementor-heading-title elementor-size-default">
                      Google Analytics
                    </h2>{" "}
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-f1df17b elementor-widget elementor-widget-text-editor"
                  data-id="f1df17b"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div class="elementor-widget-container">
                    <div
                      id="section-F1M0hpSHnl"
                      class="fullSection none noBorder radius0 none c-section c-wrapper section-F1M0hpSHnl"
                      data-v-71a73cda=""
                    >
                      <div class="inner" data-v-71a73cda="">
                        <div
                          id="row-WPHSSn-ccn_"
                          class="row-align-center none noBorder radius0 none c-row c-wrapper row-WPHSSn-ccn_"
                          data-v-71a73cda=""
                        >
                          <div class="inner" data-v-71a73cda="">
                            <div
                              id="col-0APK0YXR6y-"
                              class="c-column c-wrapper col-0APK0YXR6y-"
                              data-v-71a73cda=""
                            >
                              <div
                                class="inner none noBorder radius0 none bg bgCover vertical"
                                data-v-71a73cda=""
                              >
                                <div
                                  id="paragraph--qK-ozkykkM"
                                  class="c-paragraph c-wrapper"
                                  data-v-71a73cda=""
                                >
                                  <div class="paragraph--qK-ozkykkM text-output cparagraph--qK-ozkykkM none noBorder radius0 none">
                                    <p>
                                      Google uses the data collected from its
                                      Analytics service to tailor a more
                                      personalized user experience. The
                                      information gathered by Google Analytics
                                      doesn’t contain any personally
                                      identifiable data, like your name or email
                                      address. It simply records how many
                                      visitors there were on a specific day and
                                      time frame.
                                    </p>
                                    <p>
                                      You can choose to opt out of making your
                                      activity on the Service available to
                                      Google Analytics by installing the
                                      appropriate browser add-on. The Google
                                      Analytics opt-out browser add-on prevents
                                      sharing information with Google Analytics
                                      about your activity.
                                    </p>
                                    <p>
                                      For additional information on Google’s
                                      privacy practices, visit the Google
                                      Privacy Terms web page
                                      <a
                                        class="link--external"
                                        href="https://policies.google.com/privacy?hl=en"
                                        rel="noopener noreferrer"
                                      >
                                        &nbsp;found here.
                                      </a>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-fe8d1ff elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="fe8d1ff"
          data-element_type="section"
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-447c109"
              data-id="447c109"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-aed6d02 elementor-widget elementor-widget-heading"
                  data-id="aed6d02"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h2 class="elementor-heading-title elementor-size-default">
                      Behavioral Remarketing
                    </h2>{" "}
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-8aa64ec elementor-widget elementor-widget-text-editor"
                  data-id="8aa64ec"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div class="elementor-widget-container">
                    <div
                      id="section-F1M0hpSHnl"
                      class="fullSection none noBorder radius0 none c-section c-wrapper section-F1M0hpSHnl"
                      data-v-71a73cda=""
                    >
                      <div class="inner" data-v-71a73cda="">
                        <div
                          id="row-WPHSSn-ccn_"
                          class="row-align-center none noBorder radius0 none c-row c-wrapper row-WPHSSn-ccn_"
                          data-v-71a73cda=""
                        >
                          <div class="inner" data-v-71a73cda="">
                            <div
                              id="col-0APK0YXR6y-"
                              class="c-column c-wrapper col-0APK0YXR6y-"
                              data-v-71a73cda=""
                            >
                              <div
                                class="inner none noBorder radius0 none bg bgCover vertical"
                                data-v-71a73cda=""
                              >
                                <div
                                  id="paragraph--qK-ozkykkM"
                                  class="c-paragraph c-wrapper"
                                  data-v-71a73cda=""
                                >
                                  <div class="paragraph--qK-ozkykkM text-output cparagraph--qK-ozkykkM none noBorder radius0 none">
                                    <div
                                      id="paragraph-5WSxFerebVY"
                                      class="c-paragraph c-wrapper"
                                      data-v-71a73cda=""
                                    >
                                      <div class="paragraph-5WSxFerebVY text-output cparagraph-5WSxFerebVY none noBorder radius0 none">
                                        <div>
                                          <p>
                                            Intermedia Marketing Group LLC uses
                                            remarketing services to advertise on
                                            other third-party websites you visit
                                            after visiting the Service. Cookies
                                            are used to inform, optimize, and
                                            serve advertisements based on prior
                                            visits to the Service.
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-a36edeb elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="a36edeb"
          data-element_type="section"
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-271b77e"
              data-id="271b77e"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-bd16327 elementor-widget elementor-widget-heading"
                  data-id="bd16327"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h2 class="elementor-heading-title elementor-size-default">
                      Google AdWords
                    </h2>{" "}
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-22217b7 elementor-widget elementor-widget-text-editor"
                  data-id="22217b7"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div class="elementor-widget-container">
                    <div
                      id="section-F1M0hpSHnl"
                      class="fullSection none noBorder radius0 none c-section c-wrapper section-F1M0hpSHnl"
                      data-v-71a73cda=""
                    >
                      <div class="inner" data-v-71a73cda="">
                        <div
                          id="row-WPHSSn-ccn_"
                          class="row-align-center none noBorder radius0 none c-row c-wrapper row-WPHSSn-ccn_"
                          data-v-71a73cda=""
                        >
                          <div class="inner" data-v-71a73cda="">
                            <div
                              id="col-0APK0YXR6y-"
                              class="c-column c-wrapper col-0APK0YXR6y-"
                              data-v-71a73cda=""
                            >
                              <div
                                class="inner none noBorder radius0 none bg bgCover vertical"
                                data-v-71a73cda=""
                              >
                                <div
                                  id="paragraph--qK-ozkykkM"
                                  class="c-paragraph c-wrapper"
                                  data-v-71a73cda=""
                                >
                                  <div class="paragraph--qK-ozkykkM text-output cparagraph--qK-ozkykkM none noBorder radius0 none">
                                    <div
                                      id="paragraph-kU3x5aYZM-j"
                                      class="c-paragraph c-wrapper"
                                      data-v-71a73cda=""
                                    >
                                      <div class="paragraph-kU3x5aYZM-j text-output cparagraph-kU3x5aYZM-j none noBorder radius0 none">
                                        <div>
                                          <p>
                                            Google AdWords is a service provided
                                            by Google Inc., which allows
                                            Intermedia Marketing Group LLC to
                                            advertise the Service through online
                                            advertisements. You can opt-out
                                            <a
                                              class="link--external"
                                              href="https://adssettings.google.com/authenticated"
                                              rel="noopener noreferrer"
                                            >
                                              &nbsp;of this advertising
                                              program&nbsp;
                                            </a>
                                            to not have data collected and used
                                            for personalized content based on
                                            visitors’ browsing behavior when
                                            using web browsers such as Chrome
                                            and Firefox.
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-903e211 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="903e211"
          data-element_type="section"
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-26a9191"
              data-id="26a9191"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-47f9a9e elementor-widget elementor-widget-heading"
                  data-id="47f9a9e"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h2 class="elementor-heading-title elementor-size-default">
                      Bing Ads Remarketing
                    </h2>{" "}
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-ff35e53 elementor-widget elementor-widget-text-editor"
                  data-id="ff35e53"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div class="elementor-widget-container">
                    <div
                      id="section-F1M0hpSHnl"
                      class="fullSection none noBorder radius0 none c-section c-wrapper section-F1M0hpSHnl"
                      data-v-71a73cda=""
                    >
                      <div class="inner" data-v-71a73cda="">
                        <div
                          id="row-WPHSSn-ccn_"
                          class="row-align-center none noBorder radius0 none c-row c-wrapper row-WPHSSn-ccn_"
                          data-v-71a73cda=""
                        >
                          <div class="inner" data-v-71a73cda="">
                            <div
                              id="col-0APK0YXR6y-"
                              class="c-column c-wrapper col-0APK0YXR6y-"
                              data-v-71a73cda=""
                            >
                              <div
                                class="inner none noBorder radius0 none bg bgCover vertical"
                                data-v-71a73cda=""
                              >
                                <div
                                  id="paragraph--qK-ozkykkM"
                                  class="c-paragraph c-wrapper"
                                  data-v-71a73cda=""
                                >
                                  <div class="paragraph--qK-ozkykkM text-output cparagraph--qK-ozkykkM none noBorder radius0 none">
                                    <p>
                                      Bing Ads Remarketing is provided by
                                      Microsoft Inc. You can also choose to
                                      opt-out of Bing Ads by following their
                                      <a
                                        class="link--external"
                                        href="https://about.ads.microsoft.com/en-us/resources/policies/personalized-ads"
                                        rel="noopener noreferrer"
                                      >
                                        &nbsp;instructions located here.
                                      </a>
                                    </p>
                                    <p>
                                      Learn about Microsoft privacy policies by
                                      visiting their&nbsp;
                                      <a
                                        class="link--external"
                                        href="https://privacy.microsoft.com/en-us/PrivacyStatement"
                                        rel="noopener noreferrer"
                                      >
                                        Privacy Policy page.
                                      </a>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-5b46a23 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="5b46a23"
          data-element_type="section"
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-2fcc159"
              data-id="2fcc159"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-6316d62 elementor-widget elementor-widget-heading"
                  data-id="6316d62"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h2 class="elementor-heading-title elementor-size-default">
                      Links to Other Sites
                    </h2>{" "}
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-567f703 elementor-widget elementor-widget-text-editor"
                  data-id="567f703"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div class="elementor-widget-container">
                    <div
                      id="section-F1M0hpSHnl"
                      class="fullSection none noBorder radius0 none c-section c-wrapper section-F1M0hpSHnl"
                      data-v-71a73cda=""
                    >
                      <div class="inner" data-v-71a73cda="">
                        <div
                          id="row-WPHSSn-ccn_"
                          class="row-align-center none noBorder radius0 none c-row c-wrapper row-WPHSSn-ccn_"
                          data-v-71a73cda=""
                        >
                          <div class="inner" data-v-71a73cda="">
                            <div
                              id="col-0APK0YXR6y-"
                              class="c-column c-wrapper col-0APK0YXR6y-"
                              data-v-71a73cda=""
                            >
                              <div
                                class="inner none noBorder radius0 none bg bgCover vertical"
                                data-v-71a73cda=""
                              >
                                <div
                                  id="paragraph--qK-ozkykkM"
                                  class="c-paragraph c-wrapper"
                                  data-v-71a73cda=""
                                >
                                  <div class="paragraph--qK-ozkykkM text-output cparagraph--qK-ozkykkM none noBorder radius0 none">
                                    <div
                                      id="section-EvNcU5TJN-"
                                      class="fullSection none noBorder radius0 none c-section c-wrapper section-EvNcU5TJN-"
                                      data-v-71a73cda=""
                                    >
                                      <div class="inner" data-v-71a73cda="">
                                        <div
                                          id="row-o13AODBG655"
                                          class="row-align-center none noBorder radius0 none c-row c-wrapper row-o13AODBG655"
                                          data-v-71a73cda=""
                                        >
                                          <div class="inner" data-v-71a73cda="">
                                            <div
                                              id="col-BcJ03MPzZ0n"
                                              class="c-column c-wrapper col-BcJ03MPzZ0n"
                                              data-v-71a73cda=""
                                            >
                                              <div
                                                class="inner none noBorder radius0 none bg bgCover vertical"
                                                data-v-71a73cda=""
                                              >
                                                <div
                                                  id="paragraph-NYMJ9DwUFBf"
                                                  class="c-paragraph c-wrapper"
                                                  data-v-71a73cda=""
                                                >
                                                  <div class="paragraph-NYMJ9DwUFBf text-output cparagraph-NYMJ9DwUFBf none noBorder radius0 none">
                                                    <div>
                                                      <p>
                                                        Our Service may contain
                                                        links to other sites. If
                                                        you click on a
                                                        third-party link, you
                                                        will be directed to that
                                                        site. Note that these
                                                        external sites are not
                                                        operated by us.
                                                        Therefore, we strongly
                                                        advise you to review the
                                                        Privacy Policy of these
                                                        websites. We have no
                                                        control over and assume
                                                        no responsibility for
                                                        any third-party sites or
                                                        services’ content,
                                                        privacy policies, or
                                                        practices.
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-44854c0 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="44854c0"
          data-element_type="section"
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-7f3d685"
              data-id="7f3d685"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-dc1821c elementor-widget elementor-widget-heading"
                  data-id="dc1821c"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h2 class="elementor-heading-title elementor-size-default">
                      Children's Privacy
                    </h2>{" "}
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-a3e599d elementor-widget elementor-widget-text-editor"
                  data-id="a3e599d"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div class="elementor-widget-container">
                    <div
                      id="section-F1M0hpSHnl"
                      class="fullSection none noBorder radius0 none c-section c-wrapper section-F1M0hpSHnl"
                      data-v-71a73cda=""
                    >
                      <div class="inner" data-v-71a73cda="">
                        <div
                          id="row-WPHSSn-ccn_"
                          class="row-align-center none noBorder radius0 none c-row c-wrapper row-WPHSSn-ccn_"
                          data-v-71a73cda=""
                        >
                          <div class="inner" data-v-71a73cda="">
                            <div
                              id="col-0APK0YXR6y-"
                              class="c-column c-wrapper col-0APK0YXR6y-"
                              data-v-71a73cda=""
                            >
                              <div
                                class="inner none noBorder radius0 none bg bgCover vertical"
                                data-v-71a73cda=""
                              >
                                <div
                                  id="paragraph--qK-ozkykkM"
                                  class="c-paragraph c-wrapper"
                                  data-v-71a73cda=""
                                >
                                  <div class="paragraph--qK-ozkykkM text-output cparagraph--qK-ozkykkM none noBorder radius0 none">
                                    <div
                                      id="section-uSOHPIFCdZ"
                                      class="fullSection none noBorder radius0 none c-section c-wrapper section-uSOHPIFCdZ"
                                      data-v-71a73cda=""
                                    >
                                      <div class="inner" data-v-71a73cda="">
                                        <div
                                          id="row-wWu5IuR_xca"
                                          class="row-align-center none noBorder radius0 none c-row c-wrapper row-wWu5IuR_xca"
                                          data-v-71a73cda=""
                                        >
                                          <div class="inner" data-v-71a73cda="">
                                            <div
                                              id="col-5Gm7o89tmDi"
                                              class="c-column c-wrapper col-5Gm7o89tmDi"
                                              data-v-71a73cda=""
                                            >
                                              <div
                                                class="inner none noBorder radius0 none bg bgCover vertical"
                                                data-v-71a73cda=""
                                              >
                                                <div
                                                  id="paragraph-T6sAEMYWNzz"
                                                  class="c-paragraph c-wrapper"
                                                  data-v-71a73cda=""
                                                >
                                                  <div class="paragraph-T6sAEMYWNzz text-output cparagraph-T6sAEMYWNzz none noBorder radius0 none">
                                                    <div>
                                                      <p>
                                                        Our Services do not
                                                        address anyone under the
                                                        age of 13. We do not
                                                        knowingly collect
                                                        personally identifiable
                                                        information from
                                                        children under 13. If we
                                                        discover that a child
                                                        under 13 has provided us
                                                        with personal
                                                        information, we
                                                        immediately delete this
                                                        from our servers. If you
                                                        are a parent or guardian
                                                        and you are aware that
                                                        your child has provided
                                                        us with personal
                                                        information, please
                                                        contact us so that we
                                                        will be able to take the
                                                        necessary actions.
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-0d38e45 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="0d38e45"
          data-element_type="section"
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-9338483"
              data-id="9338483"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-4f3dfda elementor-widget elementor-widget-heading"
                  data-id="4f3dfda"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h2 class="elementor-heading-title elementor-size-default">
                      Changes to This Privacy Policy
                    </h2>{" "}
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-40cafd3 elementor-widget elementor-widget-text-editor"
                  data-id="40cafd3"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div class="elementor-widget-container">
                    <div
                      id="section-F1M0hpSHnl"
                      class="fullSection none noBorder radius0 none c-section c-wrapper section-F1M0hpSHnl"
                      data-v-71a73cda=""
                    >
                      <div class="inner" data-v-71a73cda="">
                        <div
                          id="row-WPHSSn-ccn_"
                          class="row-align-center none noBorder radius0 none c-row c-wrapper row-WPHSSn-ccn_"
                          data-v-71a73cda=""
                        >
                          <div class="inner" data-v-71a73cda="">
                            <div
                              id="col-0APK0YXR6y-"
                              class="c-column c-wrapper col-0APK0YXR6y-"
                              data-v-71a73cda=""
                            >
                              <div
                                class="inner none noBorder radius0 none bg bgCover vertical"
                                data-v-71a73cda=""
                              >
                                <div
                                  id="paragraph--qK-ozkykkM"
                                  class="c-paragraph c-wrapper"
                                  data-v-71a73cda=""
                                >
                                  <div class="paragraph--qK-ozkykkM text-output cparagraph--qK-ozkykkM none noBorder radius0 none">
                                    <div
                                      id="section-cDG323Ngsfm"
                                      class="fullSection none noBorder radius0 none c-section c-wrapper section-cDG323Ngsfm"
                                      data-v-71a73cda=""
                                    >
                                      <div class="inner" data-v-71a73cda="">
                                        <div
                                          id="row-MIW5MqfJcLC"
                                          class="row-align-center none noBorder radius0 none c-row c-wrapper row-MIW5MqfJcLC"
                                          data-v-71a73cda=""
                                        >
                                          <div class="inner" data-v-71a73cda="">
                                            <div
                                              id="col-dx9NJ-0w-Hl"
                                              class="c-column c-wrapper col-dx9NJ-0w-Hl"
                                              data-v-71a73cda=""
                                            >
                                              <div
                                                class="inner none noBorder radius0 none bg bgCover vertical"
                                                data-v-71a73cda=""
                                              >
                                                <div
                                                  id="paragraph-_K0nNPub5Zx"
                                                  class="c-paragraph c-wrapper"
                                                  data-v-71a73cda=""
                                                >
                                                  <div class="paragraph-_K0nNPub5Zx text-output cparagraph-_K0nNPub5Zx none noBorder radius0 none">
                                                    <div>
                                                      <p>
                                                        We may update our
                                                        Privacy Policy from time
                                                        to time. Thus, we advise
                                                        you to review this page
                                                        periodically for any
                                                        changes. We will notify
                                                        you of any changes by
                                                        posting the new Privacy
                                                        Policy on this page.
                                                        These changes are
                                                        effective immediately
                                                        after they are posted on
                                                        this page.
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer PhoneNumber={PhoneNumber} />
    </div>
  );
}
